exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-forbidden-tsx": () => import("./../../../src/pages/admin/forbidden.tsx" /* webpackChunkName: "component---src-pages-admin-forbidden-tsx" */),
  "component---src-pages-admin-fulltimers-tsx": () => import("./../../../src/pages/admin/fulltimers.tsx" /* webpackChunkName: "component---src-pages-admin-fulltimers-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-members-index-tsx": () => import("./../../../src/pages/members/index.tsx" /* webpackChunkName: "component---src-pages-members-index-tsx" */),
  "component---src-pages-volunteers-register-tsx": () => import("./../../../src/pages/volunteers/register.tsx" /* webpackChunkName: "component---src-pages-volunteers-register-tsx" */)
}

